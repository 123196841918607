export const logPurchase = ({ transaction_id, value, items, currency }) => {
  window.gtag("event", "purchase", {
    transaction_id,
    value,
    currency,
    items,
  });
};

export const logAddToCart = ({ value, items, currency }) => {
  window.gtag("event", "add_to_cart", {
    value,
    currency,
    items,
  });
};

export const logBeginCheckout = ({ transaction_id, value, items, currency }) => {
  window.gtag("event", "begin_checkout", {
    transaction_id,
    value,
    currency,
    items,
  });
};
