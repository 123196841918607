import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AdditionalDetail from "./Components/AdditionalDetail";
// import serviceProvider from '../Cart/Json/serviceProvider';
import CardContainer from "./Components/CardContainer";
import Theme from "../../styles/Theme";
import OrderConfirm from "./Components/OrderConfirm";
// import GuestOrderConfirm from './Components/GuestOrderConfirm';
import { Div, MDLabel, Box } from "../../styles/Styles";
// import * as payAction from '../../store/actions/PaymentAction';
import { confirmOrderAfterPayment } from "../../services/PaymentServices";
import * as types from "../../store/actions/types";
import { isLoggedIn } from "../../shared/utils";
import Loader from "../../components/Loader/Loader";
import { loadCartData } from "../../store/actions/BookingFlowServicesAction";
import { getOrderDetails } from "../../services/OrdersService";
import { logPurchase } from "../../shared/Analytics";

const OrderConfirmPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const { orderData, requestPickUp, orderConfirm } = useSelector((state) => state.PaymentReducer);

  const { search } = useLocation();
  const orderId = new URLSearchParams(search).get("order_id");
  const isFreeOrder = new URLSearchParams(search).get("isFreeOrder");

  const addGoogleEventTagOnCheckout = (response) => {
    const {
      order_id,
      order: { total_price, order_items },
    } = response;
    logPurchase({ transaction_id: order_id, value: total_price, currency: "SEK", items: order_items });
  };

  useEffect(() => {
    async function orderConfirmation() {
      setLoading(true);
      try {
        const response = isFreeOrder ? await getOrderDetails(orderId) :  await confirmOrderAfterPayment(orderId);
        loadOrderData(response);
        addGoogleEventTagOnCheckout(response);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
      sessionStorage.clear();
    }
    if (orderId && orderId !== "") {
      orderConfirmation();
    }
  }, [orderId]);

  const loadOrderData = (response) => {
    if (!isFreeOrder) {
      localStorage.setItem("orderData", JSON.stringify(response.order || response));
    }
    const merchantData =
      isFreeOrder ? JSON.parse(localStorage.getItem("orderData") || {}) : JSON.parse(response.merchant_data);
    const { order: { order_items: orderItems = merchantData.order_items } = {} } = response;
    const { dog_update_tokens: tokens = [] } = isFreeOrder ? merchantData : response;

    const filteredAssignments = tokens.map((token) => {
      const { dog_id: dogId, api_token: apiToken } = token;
      const assignmentIndex = orderItems.findIndex((obj) => obj.dog_id === dogId);
      const dog = orderItems[assignmentIndex];
      return {
        ...dog,
        dog: { ...dog.dog, isSterilized: "", isInsured: "", api_token: apiToken },
      };
    });
    setOrderItems(filteredAssignments)
    dispatch({
      type: types.ORDER_DATA,
      payload: response.order || response,
    });
    dispatch({
      type: types.PAYMENT_COMPLETE,
      payload: response?.order?.id,
    });
    const newDog = localStorage?.getItem?.("newdog") ? JSON.parse(localStorage.newdog) : false;

    if (requestPickUp && !newDog) {
      dispatch({
        type: types.ORDER_CONFIRM,
        payload: response?.order?.id,
      });
    }
    sessionStorage.clear();
    setLoading(false);
    if (isLoggedIn()) {
      dispatch(loadCartData());
    }
  }
  return (
    <>
      <Div className="container" px={2}>
        {!orderConfirm && (
          <Div my={5} display="flex" justifyContent="center">
            <MDLabel fontSize={Theme.fontSizes.xl}>
              <FormattedMessage id="order_confirm_processed_message" defaultMessage="Your order has been processed!"/>
            </MDLabel>
          </Div>
        )}
        {loading ? (
          <Div>
            <CardContainer cardTitle={<FormattedMessage id="label_additional_information" defaultMessage="Additional information" />}>
              <Box mt={4}>
                <Loader height="30px" width="20%" />
              </Box>
              <Box mt={2}>
                <Loader height="75px" width="75%" />
              </Box>
              <Box mt={2} justifyContent="space-evenly">
                <Loader height="50px" width="20%" />
                <Loader height="50px" width="20%" />
              </Box>
            </CardContainer>
          </Div>
        ) : orderConfirm ? (
          <OrderConfirm isLoggedIn={isLoggedIn()} />
        // ) : !isLoggedIn() && orderConfirm ? (
        //   <GuestOrderConfirm />
        ) : (
          <Div>
            {isLoggedIn && orderItems.length === 0
              ? <OrderConfirm isLoggedIn={isLoggedIn()} />
              : (
                <CardContainer cardTitle={<FormattedMessage id="label_additional_information" defaultMessage="Additional information" />}>
                  {(!isLoggedIn() || orderItems.length)
              && <AdditionalDetail paymentData={orderData.assignments} orderItems={orderItems} />}
                </CardContainer>
              )}
          </Div>
        )}
      </Div>
    </>
  );
};

export default OrderConfirmPage;
