import { setPaymentsDetails } from "../../services/BookingFlowServices";
import * as types from "./types";
import { availabilityErrorPopup, bannedPopup, emailErrorPopup } from "./BookingFlowServicesAction";
import Constants from "../../shared/Constants";
import Config from "../../config";

import { addOrder } from "../../services/OrdersService";
import { logBeginCheckout } from "../../shared/Analytics";

export const loadCartContent = (data) => (dispatch) => {
  dispatch({
    type: types.LOAD_CART_CONTENT,
    payload: data,
  });
};
export const moveToNextStep = () => (dispatch) => {
  dispatch({
    type: types.MOVE_TO_NEXT_PAGE,
  });
};
export const moveToPrevStep = () => (dispatch) => {
  dispatch({
    type: types.MOVE_TO_PREV_PAGE,
  });
};
export const submitAboutDogForm = (data, page) => (dispatch) => {
  dispatch({
    payload: { data, page },
    type: types.SUBMIT_ABOUT_DOG_FORM,
  });
};
export const submitContactDetails = (data) => (dispatch) => {
  dispatch({
    payload: data,
    type: types.SUBMIT_CONTACT_FORM,
  });
};

export const emptyPaymentSnippet = () => (dispatch) => {
  dispatch({
    type: types.PAYMENT_SNIPPET,
    payload: {},
  });
};

const addGoogleLogEventToCheckout = (orderData, payload) => {
  const { order_id } = orderData;
  const { order_total, assignment_list } = payload;
  logBeginCheckout({
    transaction_id: order_id,
    value: order_total,
    currency: "SEK",
    items: assignment_list,
  });
};

export const requestPaymentAction = () => async (dispatch, getState) => {
  dispatch({
    type: types.REQUEST_PAYMENT,
  });
  const {
    PaymentReducer: { payloadData, isLogged },
  } = getState();
  const payload = { ...payloadData };
  if (!isLogged) {
    payload.email = sessionStorage.getItem("guestEmail");
    payload.firstname = sessionStorage.getItem("guestFirstName");
    payload.lastname = sessionStorage.getItem("guestLastName");
    payload.phone_number = sessionStorage.getItem("guestPhoneNumber");
    payload.create_user = JSON.parse(sessionStorage.getItem("guestCreateGuestUser")) || false;
    payload.merchant_urls = {
      terms: "https://www.example.com/terms.html",
      checkout: `https://www.example.com/checkout.html?order_id={checkout.order.id}&isChecked=${payload.create_user}`,
      confirmation: `${Config.orderConfirmUrl}&isChecked=${payload.create_user}`,
      push: `https://www.example.com/api/push?order_id={checkout.order.id}&isChecked=${payload.create_user}`,
    }
  }

  await setPaymentsDetails(payload).then(
    (res) => {
      const { order: resOrder = {} } = res;
      addGoogleLogEventToCheckout(res, payload);
      localStorage.setItem("orderData", JSON.stringify(resOrder));

      dispatch({
        type: types.PAYMENT_SNIPPET,
        payload: res,
      });
      dispatch({
        type: types.ORDER_DATA,
        payload: res.order,
      });
    },
    (error) => {
      const { response: { data: responsedata = "", errors = [], status = 0 } = {} } = error || {};
      const {
        HTTP_STATUS: { UNPROCESSABLE_ENTITY },
        CUSTOM_ERROR_CODE: { BANNED_CUSTOMER, SERVICE_PROVIDER_HAS_BOOKING }
      } =Constants;
      const { errors: { email = "" } = {} } = responsedata;

      if (status === UNPROCESSABLE_ENTITY) {
        // stop user only user is banned
        if (responsedata.error_code === BANNED_CUSTOMER) {
          dispatch(bannedPopup(true));
        }
        if (responsedata.error_code === SERVICE_PROVIDER_HAS_BOOKING) {
          dispatch(availabilityErrorPopup(true));
        }
        if (Object.keys(errors).find(obj => obj.contains("slot_id"))) {
          dispatch(availabilityErrorPopup(true));
        }
        if (email) {
          dispatch(emailErrorPopup(true));
        }
      }
    },
  );
};

export const paymentComplete = (data) => (dispatch) => {
  dispatch({
    type: types.PAYMENT_COMPLETE,
    payload: data,
  });
};

export const setPayLoaData = () => (dispatch) => {
  dispatch({
    type: types.REQUEST_PAYMENT,
  });
};

export const placeOrder = () => async (dispatch, getState) => {
  dispatch({
    type: types.REQUEST_PAYMENT,
  });
  const {
    PaymentReducer: { payloadData },
  } = getState();
  await addOrder({ ...payloadData, isFreeOrder: true }).then((res) => {
    const [ order ] = res.data;
    localStorage.setItem("orderData", JSON.stringify(order));
    dispatch({
      type: types.ORDER_DATA,
      payload: order ,
    });
    dispatch({
      type: types.PAYMENT_COMPLETE,
      payload: order.id,
    });
  });
}
